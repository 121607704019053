import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";

import StaticWebsite from "components/serviceFeatures/web/StaticWebsite.js";
import ECommerceWebsite from "components/serviceFeatures/web/ECommerceWebsite";
import IntegratedBookingSystem from "components/serviceFeatures/web/IntegratedBookingSystem";
import WebDevelopmentProcess from "components/serviceFeatures/web/WebDevelopmentProcess";

import { SectionHeading } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Description = tw(SectionDescription)`w-full`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Web Application Development" }) => {
  
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>We design and develop websites that deliver outstanding user experiences. Every new website we design is unique, promising to capture your brand personality and offering. All our websites are technically optimised and responsive across the latest devices, with custom functionality if required. We can manage the whole process from design to development and testing. Alternatively, if you already have the designs, we can complete the CMS integration for you.</p>
          </Text>
          <StaticWebsite />
          <ECommerceWebsite />
          <IntegratedBookingSystem />
          <WebDevelopmentProcess />
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
