import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-12 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/2 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
  cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Differentiators:",
      features: [
        "Reduced spend on application support with considerable cost savings",
        "Better engagement in high value projects", 
        "Enhanced visibility, greater resource optimization and allocation",
        "Reduced risk and unprecedented level of visibility, control, and optimization",
        "Automated desired behaviors and outcomes to institutionalize success"
      ],
      description: "We want to make sure that your website meets its business objectives and has a commercial impact. Therefore, every website design project starts with a research phase where our aim is to build a thorough understanding of your business; your brand, your product or service, and find out exactly you’re looking to achieve. We also analyse your target market and evaluate the competitive landscape. This information is used to direct our design and development decisions going forward."
    },
    {
      imageSrc: SupportIconImage,
      title: "Our Service Portfolio:",
      features: [
        "Application enhancements",
        "24x7 support and maintenance of application systems",
        "Application tuning and configurations",
        "New features and functionality management",
        "Application / database migration & integration"
      ],
      description: "Our designers will begin by completing a set of wireframes for bespoke pages to agree on a page structure, UI and functionality. These can be used to map out user flows. Then, we will start the exciting part: conceptual design. Adopting a mobile-first approach to design, and by utilising our findings from the research phase, we will produce initial concepts. We will seek to collaborate with you from a very early stage. This ensures that the design phase is used efficiently and allows us to iterate and develop the designs with your involvement."
    }
  ],
  subheading = "Our Support Portfolio",
  description = "CIS aims to provide improved business alignment with its application support service portfolio and strong visibility of effort enabling fact-based decision making and enhanced credibility withing the business community. Here is a quick glimpse of how CIS can deliver double digit savings while providing greater IT alignment to you:",
  features = ["Available in 7 Locations", "Premium Internet Backbone", "99.99% Uptime SLA"],
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description style={{maxWidth: "70%"}}>{description}</Description>}
        <ThreeColumnContainer style={{maxWidth: "100%"}}>
          {cards.map((card, i) => (
            <Column key={i} style={{maxWidth: "100%", textAlign: "left"}}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <span>
                <FeatureList>
                  {card.features.map((feature, index) => (
                    <Feature key={index}>
                      <FeatureIcon />
                      <FeatureText style={{maxWidth: "100%", textAlign: "left"}}>{feature}</FeatureText>
                    </Feature>
                  ))}
                </FeatureList>
                </span>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
