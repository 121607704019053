import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { initializeApp } from "firebase/app";
import { collection, addDoc, getFirestore } from "firebase/firestore"; 
import EmailIllustrationSrc from "images/email-illustration.svg";
var axios = require('axios');
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const firebaseConfig = {
  apiKey: "AIzaSyBSAv9-Kc12jG3qkDsicnjvpsT_5lJvmWg",
  authDomain: "cis-website-103f7.firebaseapp.com",
  projectId: "cis-website-103f7",
  storageBucket: "cis-website-103f7.appspot.com",
  messagingSenderId: "511984991580",
  appId: "1:511984991580:web:df603fdc360bcf45ef1599",
  measurementId: "G-2V78D9VWYJ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "If you would like to make an enquiry, please use the form below and our friendly team will get back to you within one business day. Alternatively, you can email us at cyberimprintsolution@gmail.com",
  submitButtonText = "Send",
  textOnLeft = true,
}) => {

  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const sendQuery = () => {
    
    var data = {
      name: document.getElementsByName('name')[0].value,
        email: document.getElementsByName('email')[0].value,
        subject: document.getElementsByName('subject')[0].value,
        message: document.getElementsByName('message')[0].value      
    };

    var config = {
      method: 'post',
      url: 'https://services.cyberimprintsolutions.com/api/userQuery/saveQuery',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });

  }

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            
              <Input type="email" name="email" placeholder="Your Email Address" style={{width: "100%"}} />
              <Input type="text" name="name" placeholder="Full Name" style={{width: "100%"}} />
              <Input type="text" name="subject" placeholder="Subject" style={{width: "100%"}} />
              <Textarea name="message" placeholder="Your Message Here" style={{width: "100%"}} />
              <SubmitButton type="submit" onClick={sendQuery}>{submitButtonText}</SubmitButton>
            
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
