import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";

import TacticallyInclined from "components/serviceFeatures/support/TacticallyInclined.js";
import ResilientBusinessValue from "components/serviceFeatures/support/ResilientBusinessValue";
import RoundtheClockSupport from "components/serviceFeatures/support/RoundtheClockSupport";
import SupportPortfolio from "components/serviceFeatures/support/SupportPortfolio";

import { SectionHeading } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Description = tw(SectionDescription)`w-full`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Application Support" }) => {
  
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Business-critical applications are essential to run the enterprise operations smoothly however, they come with a huge price tag. From the perspective of an IT firm, 75% of IT budgets are consumed by persistent costs that are essential to manage IT application support functions. However, the present competitive scenario demands major investments in innovation than maintenance. Striking a perfect balance between these two grounds is what makes your business strong.</p>
            <p>Contrary to the common beliefs, you can have both innovation and maintenance run together rather than leading mutually exclusive lives, only if you knew what to do. CIS can bring down the additional efforts required on your infrastructure maintenance and empower you to invest your resources more strategically towards innovation. CIS’s application maintenance and support activities encourage continuous evolvement of legacy applications and cost-effective innovative measures.</p>
          </Text>
          <TacticallyInclined />
          <ResilientBusinessValue />
          <RoundtheClockSupport />
          <SupportPortfolio />
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
