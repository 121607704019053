import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import StatsIllustrationSrc from "assets/img/img/software-process.jpg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ImgCol = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(ImgCol)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const ThreeColumnContainer = styled.div`
  ${tw`mt-12 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/4 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-8 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
  cards = [
    {
      imageSrc: ShieldIconImage,
      title: "The Research Phase",
      description: "We want to make sure that your website meets its business objectives and has a commercial impact. Therefore, every website design project starts with a research phase where our aim is to build a thorough understanding of your business; your brand, your product or service, and find out exactly you’re looking to achieve. We also analyse your target market and evaluate the competitive landscape. This information is used to direct our design and development decisions going forward."
    },
    {
      imageSrc: SupportIconImage,
      title: "The Design Phase",
      description: "Our designers will begin by completing a set of wireframes for bespoke pages to agree on a page structure, UI and functionality. These can be used to map out user flows. Then, we will start the exciting part: conceptual design. Adopting a mobile-first approach to design, and by utilising our findings from the research phase, we will produce initial concepts. We will seek to collaborate with you from a very early stage. This ensures that the design phase is used efficiently and allows us to iterate and develop the designs with your involvement."
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Development",
      description: "Once the designs are completed and signed off by you, your website will be built in HTML and CSS. At this point, you will get a feel for how your website responds on various devices. Following this, our back-end developers will connect your website with your chosen Content Management System, develop any custom functionality, and integrate your website with new or pre-existing systems, if required."
    },
    {
      imageSrc: SupportIconImage,
      title: "Testing & Launch",
      description: "All websites undergo rigorous testing at different stages of the process including browser, QA and functional testing to help us spot and fix any issues. Your website will also be deployed to a UAT environment for you to test. There will be a phase for you to upload your content to the CMS, Servers or Cloud so that you’re all set and ready for the big launch."
    }
  ],
  subheading = "System Software Development Process",
  description = "We manage the entire process, from system architecture through to UI and UX design, development, testing and launch. Initially, we will work with you closely to understand exactly what you're looking to achieve with your new system. We will provide you with ongoing updates throughout the project via calls, our Support system and meetings - we actively encourage your involvement and feedback during key phases to help ensure the final solution meets your expectations. We are determined to have a proud record in developing long-term relationships with our clients; this means that after launch, we can provide you with ongoing support, and updates when required.",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description style={{maxWidth: "80%"}}>{description}</Description>}
        <ImageColumn css={imageContainerCss} style={{height: '30rem', width: '100%'}}>
          {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="" />}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <ThreeColumnContainer style={{maxWidth: "100%"}}>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
