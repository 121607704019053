import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Key Terms" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: January 1, 2022</p>

            <h2>Charges</h2>
                <p style={{marginBottom: '3%'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please see the charges below for the registration of domains. This charge includes the purchase of the domain, the initial pointing of the domain to your required name server, and an administration fee.</p>
                <p style={{marginBottom: '3%'}}><strong>Yet To Decide</strong> for .com domains + server(if required) (per year)</p>
                <p style={{marginBottom: '3%'}}><strong>Yet To Decide</strong> for .co.in domains + server(if required) (per 2 years)</p>
                <p style={{marginBottom: '3%'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>60 days</strong> prior to the expiration of your domain, you will receive an email notifying you that the domain is going to expire. If you wishto keep the domain you do not need to contact us. If you want to expire the domain please let us know by emailing <strong>cyberImprintsolution@gmail.com</strong>  and we will let the domain expire.</p>
                <p style={{marginBottom: '3%'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>30 days</strong> prior to the expiry of the domain your will receive an invoice for the domain renewal. Once this is paid, we will renew the domain for you for the period specified on the invoice.</p>
                <p style={{marginBottom: '3%'}}>There are no additional charges for the renewal of the domain, the costs for renewal are included in the invoiced amount.</p>
                <p style={{marginBottom: '5%'}}>If you are interested in us registering an alternative domain to .com or .co.in for you, simply email us at <strong>cyberImprintsolution@gmail.com</strong> for prices. </p>

            <h2>Domain transfers</h2>
            <p style={{paddingBottom: '5%'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you wish to transfer a domain that we have registered to a new registrar we will charge up to <strong>Yet To Decide</strong> as the labour cost. If you already have a contract with us for other work, the transfer will be charged at your contracted rate.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
