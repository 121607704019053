import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";

import SoftwareDevelopmentProcess from "components/serviceFeatures/software/SoftwareDevelopmentProcess.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { SectionHeading } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const Description = tw(SectionDescription)`w-full`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-12 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/2 max-w-xs`}
`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;
export default ({
  cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Software development services we provide:",
      features: [
        "Business Applications",
        "Booking Systems", 
        "Client Portals",
        "E-Commerce Systems",
        "Enterprise Systems",
        "Legacy Systems & Modernisation",
        "Logistics Software", 
        "Ticket Booking Software",
        "Tour Operator Systems"
      ],
      description: "We want to make sure that your website meets its business objectives and has a commercial impact. Therefore, every website design project starts with a research phase where our aim is to build a thorough understanding of your business; your brand, your product or service, and find out exactly you’re looking to achieve. We also analyse your target market and evaluate the competitive landscape. This information is used to direct our design and development decisions going forward."
    },
    {
      imageSrc: SupportIconImage,
      title: "Professional Software services we provide:",
      features: [
        "Domain Name Management",
        "Project Management",
        "Software Consultancy",
        "System Architecture",
        "Testing Services"
      ],
      description: "Our designers will begin by completing a set of wireframes for bespoke pages to agree on a page structure, UI and functionality. These can be used to map out user flows. Then, we will start the exciting part: conceptual design. Adopting a mobile-first approach to design, and by utilising our findings from the research phase, we will produce initial concepts. We will seek to collaborate with you from a very early stage. This ensures that the design phase is used efficiently and allows us to iterate and develop the designs with your involvement."
    }
  ],
    headingText = "System Software Development",
    imageContainerCss = null,
    imageCss = null 
  }) => {
  
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>We develop custom software solutions that meet your specific business requirements. By utilising the latest technologies and adopting the latest development approaches, we are able to solve complex business problems that help to streamline your workflow, improve efficiency and open doors to new opportunities. Our developers will work with you closely to analyse your business needs, adopting a tailored approach to create a bespoke software solution that delivers on each of your objectives. Please see below for our full suite of software services.</p>
          </Text>
          <ThreeColumnContainer style={{maxWidth: "100%"}}>
          {cards.map((card, i) => (
            <Column key={i} style={{maxWidth: "100%", textAlign: "left"}}>
              <Card href={card.url}>
                <span className="title">{card.title}</span>
                <span>
                <FeatureList>
                  {card.features.map((feature, index) => (
                    <Feature key={index}>
                      <FeatureIcon />
                      <FeatureText style={{maxWidth: "100%", textAlign: "left"}}>{feature}</FeatureText>
                    </Feature>
                  ))}
                </FeatureList>
                </span>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
          <SoftwareDevelopmentProcess />
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
