import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }
  
  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Web Development",
      primaryUrl: "/service/web-development",
      description: "We design and develop websites that deliver outstanding user experiences with the best quality and innovative ideas."
    },
    {
      imageSrc: ShieldIconImage,
      title: "Software Development",
      primaryUrl: "/service/software-development",
      description: "We build bespoke software solutions with custom functionality on user-friendly interfaces including business apps and web portal solutions."
    },
    {
      imageSrc: ShieldIconImage,
      title: "Mobile Development",
      primaryUrl: "/service/mobile-development",
      description: "We develop beautiful and interactive mobile applications in a fun and dynamic way which boosts customer engagements."
    },
    {
      imageSrc: SupportIconImage,
      title: "Application Support",
      primaryUrl: "/service/application-support",
      description: "We support all types of web and system applications and fix the issues customer faces in their applications. We also craft new and re-imagine existing brands by combining conceptual design and market research."
    },
    // { imageSrc: SupportIconImage, title: "Application Support" },
    // { imageSrc: CustomizeIconImage, title: "Web Development" },
    // { imageSrc: ReliableIconImage, title: "Software Development" },
    // { imageSrc: FastIconImage, title: "Mobile Development" },
    // { imageSrc: SimpleIconImage, title: "Product Expansion" }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Professional <span tw="text-primary-500">Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card className="serviceName">
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
              <span style={{width: "100%", fontSize: "small", fontWeight: "bold", color: "cornflowerblue", textAlign: "right"}}>
                <a href={card.primaryUrl}>Know more...</a>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
