import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionDescription } from "components/misc/Typography.js";

import LogoImage from "../../images/logo1.gif";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as LinkedInIcon } from "../../images/linkedin-icon.svg";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const footer= {
  padding: "0.9375rem 0",
  textAlign: "center",
  display: "flex",
  zIndex: "2",
  position: "relative"
};
const a= {
  color: "red",
  textDecoration: "none",
  backgroundColor: "transparent"
};
const footerWhiteFont= {
  "&,&:hover,&:focus": {
    color: "#FFFFFF"
  }
};
const right= {
  padding: "15px 0",
  margin: "0",
  float: "right!important"
};
const Description = tw(SectionDescription)`w-full`;
export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Cyber Imprint Solutions</LogoText>
          </LogoContainer>
          <CompanyDescription>
            CIS is an application development company providing best and effecient web, mobile and system applications.
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/cyberImprintSolutions">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/cyber_imprint">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/cyber-imprint-solutions">
              <LinkedInIcon />
            </SocialLink>
          </SocialLinksContainer>
          <Description>
           <span>&copy; {1900 + new Date().getYear()} , made with <span style={{color: 'red'}}>&#9829;</span>  by{" "}
            <a
              href="https://www.cyberimprintsolutions.com/"
              style={{color: "#AE4F4C", textDecoration: "none", backgroundColor: "transparent", fontWeight: "bold"}}
              target="_blank"
            >
              Cyber Imprint Solutions
            </a>
            </span>
            <a href="//www.dmca.com/Protection/Status.aspx?ID=e8bcda9a-826e-409c-96ef-b7c7a851a966" title="DMCA.com Protection Status" class="dmca-badge"> <img src ="https://images.dmca.com/Badges/dmca_protected_sml_120l.png?ID=e8bcda9a-826e-409c-96ef-b7c7a851a966"  alt="DMCA.com Protection Status" /></a>  <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
          </Description>
        </WideColumn>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            {/* <LinkListItem>
              <Link href="#">Blog</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">FAQs</Link>
            </LinkListItem> */}
            <LinkListItem>
              <Link href="/">Home</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/about-us">About Us</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/contact-us">Contact Us</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Legal</ColumnHeading>
          <LinkList>
            {/* <LinkListItem>
              <Link href="#">GDPR</Link>
            </LinkListItem> */}
            <LinkListItem>
              <Link href="/privacy">Privacy Policy</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/termOfUse">Terms of Service</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/disclaimer">Disclaimer</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/key-terms">Key Terms</Link>
            </LinkListItem>
          </LinkList>
        </Column>        
      </FiveColumns>
    </Container>
  );
};
